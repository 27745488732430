<!-- 供应商管理 -->
<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="form formMarginBtm20" ref="dataForm" :model="searchForm">
        <FormItem prop="supplier_name">
          <span class="label">供应商名称：</span>
          <Select class="iviewIptWidth250 marginRight70" clearable filterable v-model="searchForm.supplier_name">
            <Option v-for="item in supplierSelect" :value="item.name" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span class="label">营业执照有效期：</span>
          <DatePicker format="yyyy-MM-dd" type="date" class="iviewIptWidth250" placeholder="请选择" @on-change="changeTime($event, 1)" clearable></DatePicker>
          <span class="centeFont">至</span>
          <DatePicker format="yyyy-MM-dd" type="date" class="iviewIptWidth250 marginRight90" placeholder="请选择" v-model="searchForm.business_date_end" :options="options" @on-change="changeTime($event, 2)" clearable></DatePicker>
        </FormItem>
        <FormItem prop="status">
          <span class="label">状态：</span>
          <Select class="iviewIptWidth250" clearable v-model="searchForm.status">
            <Option v-for="item in statusSelect" :value="item.status_str" :key="item.status_str">{{ item.status_str }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number" :label-width="20">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnSure ml20" @click="outputList">导出</span>
        </FormItem>
      </Form>
    </div>
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}({{ item.num }})
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table :productList="listColumns" :productData="listData" :pages="pages" @on-sort-change="changeSort" @change-page="changePage" :isLoad="isLoad" :loading="isLoad" totalText="条记录" :total="total"></Table>
    </div>
    <!-- 禁用模态框 -->
    <Modal v-model="noStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认禁用？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="turnNo">确定</span>
          <span class="pageBtn finger btnCancle" @click="noStatus = false">取消</span>
        </div>
      </div>
    </Modal>
    <!-- 启用模态框 -->
    <Modal v-model="yesStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认启用？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="turnYes">确定</span>
          <span class="pageBtn finger btnCancle" @click="yesStatus = false">取消</span>
        </div>
      </div>
    </Modal>
    <!-- 概览模态框 -->
    <Modal v-model="overviewVisible" title="供应商信息概览" :footer-hide="true" :width="80">
      <Collapse v-model="collapseValue">
        <Panel name="1">
          基础信息
          <div slot="content">
            <Table :productList="basisColumns" :productData="basisData" :isLoad="isLoad"></Table>
          </div>
        </Panel>
        <Panel name="2">
          厂家信息
          <div slot="content">
            <Table :productList="factoryColumns" :productData="factoryData" :isLoad="isLoad"> </Table>
          </div>
        </Panel>
        <Panel name="3">
          产品信息
          <div slot="content">
            <Table :productList="productColumn" :productData="productData" :isLoad="isLoad"> </Table>
          </div>
        </Panel>
      </Collapse>
    </Modal>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'factoryManage',
  components: {
    Table,
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.money * 1
      })
      return totle
    },
  },
  data() {
    return {
      key: null,
      order: null,
      isLoad: true,
      navFrist: [
        {
          name: '全部',
          num: 0,
          type: 1,
        },
        {
          name: '待审核',
          num: 0,
          type: 2,
        },
        {
          name: '已审核',
          num: 0,
          type: 3,
        },
        {
          name: '审核驳回',
          num: 0,
          type: 4,
        },
      ],
      clickIndex: 1,
      listColumns: [
        {
          title: '序号',
          key: 'indexs',
          align: 'center',
          minWidth: 45,
          maxWidth: 80,
        },
        {
          title: '供应商编号',
          key: 'supplier_code',
          align: 'center',
          sortable: 'custom',
          minWidth: 110,
          maxWidth: 160,
        },
        {
          title: '供应商名称',
          align: 'center',
          minWidth: 260,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'tableFont content_left',
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    },
                  },
                },
                param.row.supplier_name
              ),
            ])
          },
        },
        {
          title: '营业执照有效期',
          key: 'business_end',
          align: 'center',
          sortable: 'custom',
          minWidth: 130,
        },
        {
          title: '经营许可证有效期',
          key: 'business_allows_end',
          align: 'center',
          sortable: 'custom',
          minWidth: 130,
          maxWidth: 170,
        },
        {
          title: '备案日期',
          key: 'regist_end',
          align: 'center',
          sortable: 'custom',
          minWidth: 100,
          maxWidth: 140,
        },
        {
          title: '联系人',
          key: 'name',
          align: 'center',
          minWidth: 70,
          maxWidth: 140,
        },
        {
          title: '联系电话',
          key: 'phone',
          align: 'center',
          minWidth: 90,
          maxWidth: 140,
        },
        {
          title: '状态',
          align: 'center',
          minWidth: 70,
          maxWidth: 130,
          render: (h, param) => {
            let str = param.row.status
            switch (str) {
              case '新增':
                return h('div', [
                  h(
                    'span',
                    {
                      // style: { color: '#525B6D' },
                    },
                    str
                  ),
                ])
              case '修改':
                return h('div', [
                  h(
                    'span',
                    {
                      // style: { color: '#525B6D' },
                    },
                    str
                  ),
                ])
              case '有效':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#1DDE85' },
                    },
                    str
                  ),
                ])
              case '过期':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#F55A52' },
                    },
                    str
                  ),
                ])
              case '近效期':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#FF8E48' },
                    },
                    str
                  ),
                ])
              case '禁用':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '#F55A52' },
                    },
                    str
                  ),
                ])
              case '审核驳回':
                return h('div', [
                  h(
                    'span',
                    {
                      style: { color: '' },
                    },
                    str
                  ),
                ])
            }
          },
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          minWidth: 200,
          maxWidth: 280,
          render: (h, param) => {
            let status = param.row.status
            switch (status) {
              case '新增':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.ship(param.row)
                        },
                      },
                    },
                    '审核'
                  ),
                ])
              case '修改':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont edit',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.ship(param.row)
                        },
                      },
                    },
                    '审核'
                  ),
                ])
              case '有效':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont edit',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.ship(param.row)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      style: {
                        marginLeft: '20px',
                      },
                      // class: 'tableFont delete',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.noStatus = true
                          this.modelIndex = param.row.supplier_id
                        },
                      },
                    },
                    '禁用'
                  ),
                  h(
                    'span',
                    {
                      class: {
                        tableFont: true,
                        disableUse: this.$data.searchAble,
                        marginLeft20: !this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.output(param.row.supplier_id)
                        },
                      },
                    },
                    '导出审批表'
                  ),
                  h(
                    'span',
                    {
                      style: {
                        marginLeft: '20px',
                      },
                      // class: 'tableFont select',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.searchAble,
                      },
                      on: {
                        click: () => {
                          this.getOverview(param.row.supplier_id)
                        },
                      },
                    },
                    '概览'
                  ),
                ])
              case '过期':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont edit',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                      },
                      style: {
                        marginRight: '20px',
                      },
                      on: {
                        click: () => {
                          this.ship(param.row)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      // class: 'tableFont delete',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.noStatus = true
                          this.modelIndex = param.row.supplier_id
                        },
                      },
                    },
                    '禁用'
                  ),
                  h(
                    'span',
                    {
                      style: {
                        marginLeft: '20px',
                      },
                      // class: 'tableFont select',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.searchAble,
                      },
                      on: {
                        click: () => {
                          this.getOverview(param.row.supplier_id)
                        },
                      },
                    },
                    '概览'
                  ),
                ])
              case '近效期':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont edit',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.ship(param.row)
                        },
                      },
                    },
                    '编辑'
                  ),
                  h(
                    'span',
                    {
                      style: {
                        marginLeft: '20px',
                      },
                      // class: 'tableFont delete',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.noStatus = true
                          this.modelIndex = param.row.supplier_id
                        },
                      },
                    },
                    '禁用'
                  ),
                  h(
                    'span',
                    {
                      // class: 'tableFont edit',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.searchAble,
                        marginLeft20: !this.deleteAble,
                      },
                      on: {
                        click: () => {
                          this.output(param.row.supplier_id)
                        },
                      },
                    },
                    '导出审批表'
                  ),
                  h(
                    'span',
                    {
                      style: {
                        marginLeft: '20px',
                      },
                      // class: 'tableFont select',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.searchAble,
                      },
                      on: {
                        click: () => {
                          this.getOverview(param.row.supplier_id)
                        },
                      },
                    },
                    '概览'
                  ),
                ])
              case '禁用':
                return h('div', [
                  h(
                    'span',
                    {
                      // class: 'tableFont delete',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.modifyAble,
                      },
                      on: {
                        click: () => {
                          this.yesStatus = true
                          this.modelIndex = param.row.supplier_id
                        },
                      },
                    },
                    '启用'
                  ),
                  h(
                    'span',
                    {
                      style: {
                        marginLeft: '20px',
                      },
                      // class: 'tableFont select',
                      class: {
                        tableFont: true,
                        disableUse: this.$data.searchAble,
                      },
                      on: {
                        click: () => {
                          this.getOverview(param.row.supplier_id)
                        },
                      },
                    },
                    '概览'
                  ),
                ])
              case '审核驳回':
                return h('div', [])
            }
          },
        },
      ],
      listData: [],
      totalList: [],
      supplierSelect: [],
      statusSelect: [{ status_str: '新增' }, { status_str: '修改' }, { status_str: '有效' }, { status_str: '近效期' }, { status_str: '过期' }, { status_str: '禁用' }, { status_str: '审核驳回' }],
      searchForm: {
        supplier_name: null,
        business_date_begin: '',
        business_date_end: '',
        status: null,
      },
      options: {}, // 时间范围设置
      pageSearch: {}, // 查询条件
      total: 1,
      pages: {
        page: 1,
        rows: 10,
      },
      examine: '',
      noStatus: false /* 禁用模态框状态 */,
      yesStatus: false /* 启用模态框状态 */,
      overviewVisible: false /* 概览模态框状态 */,
      modelIndex: 0 /* 状态改变的id和查看概览的id */,
      collapseValue: ['1', '2', '3'] /* 展开面板 */,
      /* 基础信息表头 */
      basisColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '供应商名称',
          align: 'center',
          key: 'supplier_name',
          minWidth: 300,
        },
        {
          title: '营业执照有效期',
          key: 'business_date',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '经营许可证有效期',
          key: 'operating_Date',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '生产备案日期',
          key: 'registration_date',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '销售人员授权效期',
          key: 'supplementary_date',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '联系人',
          key: 'name',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '联系电话',
          key: 'phone',
          align: 'center',
          minWidth: 100,
        },
      ],
      basisData: [
        {
          supplier_id: '1',
          supplier_name: 'JoJo',
          business_date: '2020-12-12',
          operating_Date: '2020-12-12',
          registration_date: '2020-12-12',
          supplementary_date: '2020-12-12',
          name: 'jack',
          phone: '13877777777',
        },
      ] /* 基础信息数据 */,
      /* 厂家信息表头 */
      factoryColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '厂家名称',
          key: 'name',
          align: 'center',
          minWidth: 350,
        },
        {
          title: '营业执照有效期',
          align: 'center',
          key: 'business_end',
          minWidth: 200,
          maxWidth: 250,
        },
        {
          title: '生产许可证有效期',
          key: 'effective_end_date',
          align: 'center',
          minWidth: 200,
          maxWidth: 250,
        },
        {
          title: '生产备案日期',
          key: 'filing_date',
          align: 'center',
          minWidth: 200,
          maxWidth: 250,
        },
      ],
      factoryData: [
        {
          name: '天上居',
          business_end: '2020-12-24',
          effective_end_date: '2020-12-24',
          filing_date: '2020-12-24',
          regist_end: '2020-12-24',
        },
      ] /* 厂家信息数据 */,
      /* 产品信息表头 */
      productColumn: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 55,
        },
        {
          title: '产品名称',
          align: 'center',
          key: 'name',
          minWidth: 200,
          maxWidth: 250,
        },
        {
          // title: '注册证号',
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 200,
          maxWidth: 250,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          minWidth: 350,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          minWidth: 200,
          maxWidth: 250,
        },
      ],
      productData: [
        {
          product_model_code: '2222',
          name: 'json',
          licence_code: 'FBK3BK9AUUCDKWIA',
          factory_name: 'C·C',
          valid_period: '2020-12-02',
        },
      ] /* 产品信息数据 */,
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  methods: {
    outputList() {
      let data
      let str
      data = JSON.parse(JSON.stringify(this.searchForm))
      if (this.searchForm.status) {
        switch (this.searchForm.status) {
          case '新增':
            str = '0'
            break
          case '修改':
            str = '1'
            break
          case '有效':
            str = '2'
            break
          case '禁用':
            str = '3'
            break
          case '过期':
            str = '4'
            break
          case '近效期':
            str = '5'
            break
          case '审核驳回':
            str = '6'
            break
          default:
            str = ''
            break
        }
        data.status = str
      }
      this.$http.downFile(this.$api.supplierlistexport, data).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '供应商审批表.xlsx'
        aLink.click()
      })
    },
    // 排序
    changeSort(data) {
      this.key = data.key
      this.order = data.order
      this.getTableData(this.pages, this.examine, {})
    },
    ship(row) {
      this.$router.push({
        path: '/firstCampApproval',
        query: {
          supplier_id: row.supplier_id,
        },
      })
    },
    goDetails(row) {
      this.$router.push({
        path: '/firstCampApproval',
        query: {
          supplier_id: row.supplier_id,
          is_readonly: 1,
        },
      })
    },
    addOrder() {},
    changePage(e) {
      this.pages.page = e
      this.getTableData(this.pages, this.examine, this.pageSearch)
    },
    clickNav(type) {
      // 切换tab触发的事件
      this.clickIndex = type
      if (this.clickIndex == 1) {
        this.examine = ''
        this.pages = { page: 1, rows: 10 }
        this.getTableData(this.pages, this.examine, this.pageSearch)
        this.total = this.navFrist[0].num
      }
      if (this.clickIndex == 2) {
        // 待审核
        this.examine = '1'
        this.pages = { page: 1, rows: 10 }
        this.getTableData(this.pages, this.examine, this.pageSearch)
        this.total = this.navFrist[1].num
      }
      if (this.clickIndex == 3) {
        // 已审核
        this.examine = '3'
        this.pages = { page: 1, rows: 10 }
        this.getTableData(this.pages, this.examine, this.pageSearch)
        this.total = this.navFrist[2].num
      }
      if (this.clickIndex == 4) {
        // 审核驳回
        this.examine = '2'
        this.pages = { page: 1, rows: 10 }
        this.getTableData(this.pages, this.examine, this.pageSearch)
        this.total = this.navFrist[3].num
      }
    },
    /* 获取表格数据 */
    // async getTableData(pages, examine, obj, flag = false) {
    //   let searchItem = {}
    //   Object.assign(searchItem, pages, { examine }, obj)
    //   let res = await this.$http.get(this.$api.getSupplierList, searchItem,true)
    //   if (!res.data.supplier.length) {
    //     // 当页数据为0时，跳转到第一页
    //     Object.assign(searchItem, { page: 1, rows: 10 })
    //     res = await this.$http.get(this.$api.getSupplierList, searchItem)
    //   }
    //   this.totalList = []
    //   for (const item of res.data.supplier) {
    //     this.totalList.push(item)
    //   }
    //   // console.log(this.examine);
    //   if (!this.examine) {
    //     // 只有在全部的时候会改变总数量
    //     this.navFrist[0].num = res.data.all_total
    //     this.navFrist[1].num = res.data.wait_count
    //     this.navFrist[2].num = res.data.alreay_count
    //     this.navFrist[3].num = res.data.back_count
    //   }
    //   if (flag) {
    //     // 针对在已审核tab时禁用，从而改变已审核总数
    //     this.navFrist[2].num = res.data.review_pass_total
    //   }
    //   for (const item of this.totalList) {
    //     if (item.business_end) {
    //       item.business_end = item.business_end === '长期' ? '长期' : this.$moment.unix(item.business_end).format('YYYY-MM-DD')
    //     }
    //     if (item.business_allows_end) {
    //       item.business_allows_end = item.business_allows_end === '长期' ? '长期' : this.$moment.unix(item.business_allows_end).format('YYYY-MM-DD')
    //     }
    //     if (item.regist_end) {
    //       item.regist_end = item.regist_end === '长期' ? '长期' : this.$moment.unix(item.regist_end).format('YYYY-MM-DD')
    //     }
    //   }
    //   this.listData = this.totalList
    //   this.total = res.data.total
    // },
    // 获取数据
    async getTableData(pages, examine, obj) {
      let searchItem = {}
      Object.assign(searchItem, pages, { examine }, obj)
      searchItem.sort_str = this.key
      searchItem.sort_type = this.order
      this.isLoad = true
      let res = await this.$http.get(this.$api.getSupplierList, searchItem, true)
      this.isLoad = false
      // 表格数据
      this.listData = res.data.supplier
      // 日期处理
      this.listData.forEach((item, index) => {
        item.business_end = item.business_end ? (item.business_end == '长期' ? '长期' : this.$moment(item.business_end * 1000).format('YYYY-MM-DD')) : ''
        item.business_allows_end = item.business_allows_end ? (item.business_allows_end == '长期' ? '长期' : this.$moment(item.business_allows_end * 1000).format('YYYY-MM-DD')) : ''
        item.regist_end = item.regist_end ? (item.regist_end == '长期' ? '长期' : this.$moment(item.regist_end * 1000).format('YYYY-MM-DD')) : ''
        // 增加序号
        item.indexs = index + 1 + (pages.page - 1) * 10
        // 处理空值到话用/代替
        for (var key in item) {
          if (!item[key]) {
            item[key] = '/'
          }
        }
      })
      // 条数
      this.total = res.data.total
      this.navFrist[0].num = res.data.all_total
      this.navFrist[1].num = res.data.wait_count
      this.navFrist[2].num = res.data.alreay_count
      this.navFrist[3].num = res.data.back_count
    },
    // 查询
    query() {
      this.pageSearch = []
      let business_date_begin = ''
      let business_date_end = ''
      business_date_begin = this.$moment(this.searchForm.business_date_begin).format('YYYY-MM-DD')
      business_date_end = this.$moment(this.searchForm.business_date_end).format('YYYY-MM-DD')
      let obj = {}
      this.pages = { page: 1, rows: 10 }
      if (this.searchForm.supplier_name) {
        this.$set(obj, 'supplier_name', this.searchForm.supplier_name)
      }
      if (business_date_begin !== 'Invalid date') {
        this.$set(obj, 'business_date_begin', business_date_begin)
      }
      if (business_date_end !== 'Invalid date') {
        this.$set(obj, 'business_date_end', business_date_end)
      }
      if (this.searchForm.status) {
        let str = ''
        switch (this.searchForm.status) {
          case '新增':
            str = '0'
            break
          case '修改':
            str = '1'
            break
          case '有效':
            str = '2'
            break
          case '禁用':
            str = '3'
            break
          case '过期':
            str = '4'
            break
          case '近效期':
            str = '5'
            break
          case '审核驳回':
            str = '6'
            break
          default:
            str = ''
            break
        }
        this.$set(obj, 'status', str)
      }
      this.clickIndex = 1
      this.examine = ''
      this.getTableData(this.pages, this.examine, obj)
      this.pageSearch = obj
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.searchForm.business_date_begin = e
        that.searchForm.business_date_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.searchForm.business_date_begin) - 86400000
          },
        }
      } else {
        this.searchForm.business_date_end = e
      }
    },
    /* 变为禁用状态 */
    turnNo() {
      let supplier_id = this.modelIndex
      let obj = { supplier_id }
      this.$http.put(this.$api.changeSupplierStatus, obj).then(res => {
        if (res.status) {
          this.getTableData(this.pages, this.examine, this.pageSearch)
        }
      })
      this.noStatus = false
    },
    /* 变为启用状态 */
    turnYes() {
      let supplier_id = this.modelIndex
      let obj = { supplier_id }
      this.$http.put(this.$api.changeSupplierStatus, obj).then(res => {
        if (res.status) {
          this.getTableData(this.pages, this.examine, this.pageSearch)
        }
      })
      this.yesStatus = false
    },
    /* 获取供应商名称 */
    getSupplierList() {
      this.$http.get(this.$api.getSupplierNameList).then(res => {
        if (res.status) {
          this.supplierSelect = res.data
        }
      })
    },
    /* 获取供应商信息概览 */
    getOverview(sid) {
      this.overviewVisible = true
      this.collapseValue = ['1', '2', '3']
      let supplier_id = sid
      let obj = { supplier_id }
      this.$http.get(this.$api.showOverview, obj).then(res => {
        console.log(res)
        let basics_info = res.data.basics_info
        let factory_info = res.data.factory_info
        let product_info = res.data.product_info
        for (const item of basics_info) {
          item.business_date = item.business_date ? (item.business_date == '长期' ? '长期' : this.$moment.unix(item.business_date).format('YYYY-MM-DD')) : ''
          item.operating_Date = item.operating_Date ? (item.operating_Date == '长期' ? '长期' : this.$moment.unix(item.operating_Date).format('YYYY-MM-DD')) : ''
          item.registration_date = item.registration_date ? (item.registration_date == '长期' ? '长期' : this.$moment.unix(item.registration_date).format('YYYY-MM-DD')) : ''
          item.supplementary_date = item.supplementary_date ? (item.supplementary_date == '长期' ? '长期' : this.$moment.unix(item.supplementary_date).format('YYYY-MM-DD')) : ''
        }
        for (const item of factory_info) {
          item.filing_date = item.filing_date ? (item.filing_date == '长期' ? '长期' : this.$moment.unix(item.filing_date).format('YYYY-MM-DD')) : ''
          item.effective_end_date = item.effective_end_date ? (item.effective_end_date == '长期' ? '长期' : this.$moment.unix(item.effective_end_date).format('YYYY-MM-DD')) : ''
          item.business_end = item.business_end ? (item.business_end == '长期' ? '长期' : this.$moment.unix(item.business_end).format('YYYY-MM-DD')) : ''
        }
        for (const item of product_info) {
          item.valid_period = item.valid_period ? (item.valid_period == '长期' ? '长期' : this.$moment.unix(item.valid_period).format('YYYY-MM-DD')) : ''
        }
        this.basisData = basics_info
        this.factoryData = factory_info
        this.productData = product_info
      })
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      /* let title = this.$route.meta.title
      let navName = this.$route.meta.navName
      if (this.roleMenuList.length !== 0) {
        let fatherArr = this.roleMenuList.find(val => {
          // 筛选出父菜单里的当前父菜单权限
          return val.func_name === title
        })
        let sonArr = fatherArr.children.find(val => {
          // 筛选出当前菜单的权限
          return val.func_name === navName
        })
        let permission = sonArr.permission
        let deletePermiss = permission.delete
        let insertPermiss = permission.insert
        let modifyPermiss = permission.modify
        let searchPermiss = permission.search
        // 因为页面刷新会导致vuex数据丢失，所以存储到本地session中
        let rightStr = JSON.stringify({ delete: deletePermiss, insert: insertPermiss, modify: modifyPermiss, search: searchPermiss })
        window.sessionStorage.setItem(navName, rightStr)
      }
      let rightStr = window.sessionStorage.getItem(navName)
      let rightObj = JSON.parse(rightStr)
      this.deleteAble = rightObj.delete
      this.insertAble = rightObj.insert
      this.modifyAble = rightObj.modify
      this.searchAble = rightObj.search */

      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.deleteAble = !rightStr.delete
      this.insertAble = !rightStr.insert
      this.modifyAble = !rightStr.modify
      this.searchAble = !rightStr.search
    },
    // 导出审批表
    output(id) {
      this.$http.downFile(this.$api.exportSupplier, { supplier_id: id }).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '供应商审批表.xlsx'
        aLink.click()
      })
    },
  },
  async activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      await this.getTableData(this.pages, this.examine, {})
      sessionStorage.setItem('updataCache', '1')
    }
  },

  async created() {
    let flag = this.$route.query.show
    if (flag) {
      await this.clickNav(2)
    } else {
      await this.getTableData(this.pages, this.examine, {})
    }
    this.getSupplierList()
    this.getRightVisibal()
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 100px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
/deep/ .marginLeft20 {
  margin-left: 20px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
.scontent_left {
  text-align: left;
}
.modal {
  .formMarginBtm10 {
    .ivu-form-item {
      margin-bottom: 10px !important;
    }
  }
  .label {
    width: 200px;
  }
  .money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      padding-right: 8px;
    }
  }

  .foot {
    text-align: right;
    margin-top: 20px;
  }
  .bodyFrom {
    width: 100%;
    .fromItem {
      display: flex;
      align-items: center;
      margin: 10px 0px;
      // justify-content: space-between;
      .labelTitle {
        display: flex;
        span {
          // word-wrap: normal;
          vertical-align: middle;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  // /deep/ .ivu-form-inline .ivu-form-item{
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   .ivu-form-item-content{
  //     // width: 100%;
  //     flex: 1;
  //     // display: flex;
  //     // align-items: center;
  //   }
  // }
}
.iviewIptWidth450 {
  max-width: 550px;
}
.iviewIptWidth240 {
  max-width: 240px;
}
.bitian {
  color: red;
}
.marginLeft {
  margin-left: 100px;
}
.Width185 {
  width: 200px;
}
/deep/ .ivu-radio-wrapper {
  width: 140px;
  color: #515a6e !important;
  font-size: 18px !important;
}
.ml20{
  margin-left: 20px;
}
</style>
